/*!
 * Start Bootstrap - Landing Page v5.0.4 (https://startbootstrap.com/template-overviews/landing-page)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-landing-page/blob/master/LICENSE)
 */

body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  background: url("../public/bg-masthead.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 11rem;
  padding-bottom: 11rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.nodestats {
  margin-bottom: 5rem;
}

#node-stats-nav-tab {
  border-bottom: 0 none;
}

header.masthead h1 {
  font-size: 2rem;
}

.features-icons {
  padding-top: 2rem;
  padding-bottom: 3.5rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.testimonials .testimonial-item {
  max-width: 18rem;
}

.testimonials .testimonial-item img {
  max-width: 12rem;
  /* -webkit-box-shadow: 0px 5px 5px 0px #adb5bd;
  box-shadow: 0px 5px 5px 0px #adb5bd; */
}

.call-to-action {
  position: relative;
  background-color: #343a40;
  background: url("../public/bg-masthead.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.call-to-action .overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.material-icons {
  color: #558ec7;
  cursor: default;
}

.fab {
  padding: 20px;
  padding-right: 10px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}

.home-icon {
  width: 35px;
}

.header {
  overflow: hidden;
  background-image: linear-gradient(to bottom right, #1d3752, #558ec7);
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -130px;
}

.push {
  height: 150px;
}

.footer {
  background-image: linear-gradient(to bottom right, #1d3752, #558ec7);
  text-align: right;
  color: #f2f2f2;
  height: 130px;
  /* bottom: 0%; */
  width: 100%;
  overflow: hidden;
}

.footer a {
  color: #f2f2f2;
}

.header a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  padding: 15px;
  font-size: 20px;
}

a {
  text-decoration: none;
}

a:hover {
  color:lightskyblue;
}